import React from 'react';
import dynamic from 'next/dynamic';

// Components
const HeroSvg = dynamic(() => import('@/components/organisms/HeroSvg'));

const Custom404 = () => (
	<div>
		<HeroSvg
			type="column"
			svg="skyline"
			breadcrumbs={[
				{
					id: '1b__breadcrumb-1',
					url: '/get-in-touch/',
					title: 'Contact us',
					dataGAAction: 'breadcrumb',
					dataGALabel: '404',
					dataGACategory: 'component - hero 404',
				},
			]}
			title="Sorry, the page you requested can’t be found"
			paragraph="It may be an old page that doesn't exist anymore. Try going back to the homepage or using a different link."
			linksStacked
			links={[
				{
					id: '404__link-1',
					url: '/',
					title: 'Bruntwood Homepage',
					dataGACategory: 'component - 404 link',
					dataGAAction: 'Bruntwood Group',
					dataGALabel: '',
				},
				{
					id: '404__link-2',
					url: '/get-in-touch/',
					title: 'Contact Us',
					dataGACategory: 'component - 404 link',
					dataGAAction: 'Contact Us',
					dataGALabel: '',
				},
			]}
		/>
	</div>
);

export default Custom404;
